





















































































































































































































































































































































































































































































































































































































































































































































































































































































import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Component, { mixins } from 'vue-class-component';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import debounce from 'lodash/debounce';
import Decimal from 'decimal.js';
import camelcaseKeys from 'camelcase-keys';
import printJS from 'print-js';

import { toasts } from '@/utils/toasts';
import { formatNumber as fn } from '../../../utils/number-formatting';

import EditMaterialsTransferNotesLineModal from './EditMaterialsTransferNotesLineModal.vue';
import MaterialsRequisitionNoteMixin from '../../inventory-asset-requisition-notes/views/inventory-asset-requisition-notes-mixin.vue';
import DocumentLogEntriesModal from './DocumentLogEntriesModal.vue';

import {
  lookupProduct,
  lookupEmployee,
  getAvailableSeries,
  getAvailableAssets,
  saveDraft,
  deleteDraft,
  compareLatestSeries,
  deleteCanceled,
  restoreDocument,
  cancelDocument,
  postDraft,
  getDetails,
  getPdf,
  getDocumentLogs,
} from '../api/inventory-asset-transfer-notes-api';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    EditMaterialsTransferNotesLineModal,
    DocumentLogEntriesModal,
  },
})
export default class extends mixins(MaterialsRequisitionNoteMixin) {
  loading = true;
  dataConsumYear = 2022;
  requisitionedFrom = null;

  get Decimal() {
    return Decimal;
  }

  formatNumber(input, dp) {
    return fn(input, dp);
  }

  printNumCopies = 1;
  printPreviewIframe: HTMLIFrameElement|null = null;

  beforeDestroy() {
    // Clean up the print preview IFRAME, we don't need it anymore
    if (this.printPreviewIframe) {
      this.printPreviewIframe.parentElement!.removeChild(this.printPreviewIframe);
    }
  }

  hoveredRowIndex = -1;

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  //
  // Preview modal imag source
  //
  previewModalImageSrc = '/api/bto/jpeg/_placeholder_';

  //
  // Preview modal events
  //
  async onPreviewModalCancel() {
    if (this.$route.name !== 'InventoryAssetTransferNotes.Details') {
      this.$router.replace({ name: 'InventoryAssetTransferNotes.Details', params: { id: `${this.inventoryAssetTransferNoteId}` } });
      return;
    }
    await this.initialize();
  }

  async onPreviewModalOk(evt) {
    evt.preventDefault();

    const confirmOutcome = await this.$bvModal.msgBoxConfirm('Salvarea bonului de cosum va duce la procesarea acestuia in gestiune. Dacă mai târziu vor fi identificate erori, acestea trebuie corectate printr-o stornare. Dorești să continui?', {
      centered: true,
      okTitle: 'Da',
      cancelTitle: 'Nu',
    });
    if (!confirmOutcome) {
      return;
    }

    try {
      const response = await postDraft(this.inventoryAssetTransferNoteId);
      console.log(response);
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
      return;
    }

    // We can close the modal now
    this.$bvModal.hide('preview-modal');

    toasts.success('Documentul a fost salvat ca bon de transfer obiect inventar!');
    if (this.$route.name !== 'InventoryAssetTransferNotes.Details') {
      this.$router.replace({ name: 'InventoryAssetTransferNotes.Details', params: { id: `${this.inventoryAssetTransferNoteId}` } });
      return;
    }
    await this.initialize();
  }

  documentLogs: Array<any> = [];
  items: Array<any> = [];

  fields: Array<any> = [
    {
      key: 'idx',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'product',
      label: 'Produs',
      thClass: 'tbl-col-product',
      tdClass: 'py-2 tbl-col-product',
    },
    {
      key: 'measurementUnit',
      label: 'U.M.',
      thClass: 'text-center tbl-col-mu',
      tdClass: 'py-2 text-center tbl-col-mu',
    },
    {
      key: 'requiredQuantity',
      label: 'Cant. necesară',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'requisitionedQuantity',
      label: 'Cant. consumată',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'actions',
      label: '',
      thClass: 'text-right tbl-col-actions',
      tdClass: 'text-right tbl-col-actions',
    },
  ];

  publishedFields: Array<any> = [
    {
      key: 'idx',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'product',
      label: 'Produs',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
    {
      key: 'measurementUnit',
      label: 'U.M.',
      thClass: 'text-center tbl-col-mu',
      tdClass: 'py-2 text-center tbl-col-mu',
    },
    {
      key: 'requiredQuantity',
      label: 'Cant. necesară',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'requisitionedQuantity',
      label: 'Cant. consumată',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryUnitPrice',
      label: 'Pret unitar',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryRequisitionValue',
      label: 'Valoare',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
  ];

  //
  // Quantity event handlers
  //
  onRequisitionedQuantityChanged(value) {
    this.requisitionedQuantity = value;
    if (!this.requiredQuantity) {
      this.requiredQuantity = new Decimal(value).toFixed(3);
    }
  }

  onRequiredQuantityChanged(value) {
    this.requiredQuantity = value;
    this.requisitionedQuantity = new Decimal(value).toFixed(3);
  }

  onProductSelected(value) {
    if (!value) {
      return;
    }

    this.productDescription = value.description;
    this.measurementUnit = value.measurementUnit;

    (this.$refs.productDescriptionInputControl as any).focus();
  }

  onInventorySelected(value) {
    if (!value) {
      return;
    }

    this.requisitionedFrom = value.employeeName;
  }

  onEmployeeSelected(value) {
    if (!value) {
      return;
    }

    this.requisitionedBy = value.employeeName;
  }

  validateWrapper(validator, value) {
    // Every time we go through this validator, we mark the data as not saved
    if (this.isDraft) {
      this.isSaved = false;
    }

    validator(value);
  }

  dateFormatterDataConsum(value) {
    this.dataConsumValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
    return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
  }

  async onContextDataConsum(ctx) {
    const currentDate = new Date().toISOString().substring(0, 10);

    if (currentDate < ctx.selectedYMD) {
      if (!(await this.$bvModal.msgBoxConfirm('Atenție, data de transfer a documentului este ulterioară datei curente', {
        okTitle: 'Continuă salvarea',
        cancelTitle: 'Anulează salvarea',
        centered: true,
        hideHeader: true,
        noCloseOnBackdrop: true,
      }))) {
        await this.onContextDataConsumOk(currentDate);
        return;
      }
      await this.onContextDataConsumOk(ctx.selectedYMD);
    } else {
      await this.onContextDataConsumOk(ctx.selectedYMD);
    }
  }

  async onContextDataConsumOk(value) {
    this.dataConsumValue = format(parse(value, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
    const ctxDate = parse(value, 'yyyy-MM-dd', new Date());

    if (ctxDate.getFullYear() !== this.dataConsumYear) {
      this.documentSeries = null;
      this.dataConsumYear = ctxDate.getFullYear();
      this.documentSeriesOptions = camelcaseKeys(await getAvailableSeries(this.dataConsumYear));

      if (this.documentSeriesOptions.length === 1) {
        [this.documentSeries] = this.documentSeriesOptions;
      }
    }
  }

  previewModalImageShown = false;

  // Diplay fields for the document dates
  dataConsumValue = '20 . 12 . 2020';

  //
  // Header-level multiselect fields
  //

  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;

  documentSeriesOptions: Array<any> = [];
  isDocumentSeriesOptionsLoading = false;

  // Computed current user name getter
  get currentUserFullName() {
    return this.$store.state.fullName;
  }

  // Satus
  isSaved = false;

  //
  // Header fields
  //

  inventoryAssetTransferNoteId: number|null = null;
  statusCode: number = 0;

  get isDraft(): Boolean {
    return this.statusCode === 0;
  }
  get isPublished(): Boolean {
    return this.statusCode === 1;
  }
  get isCanceled(): Boolean {
    return this.statusCode === 2;
  }
  get isDocumentCreated() : Boolean {
    return this.inventoryAssetTransferNoteId !== null;
  }

  // The entire inventory details object
  inventory: any|null = null;

  // The entire series object
  documentSeries: any|null = null;
  documentNumber = '';
  dataConsumValueDatepicker = '2020-12-20';

  //
  // Footer fields
  //
  documentNotes: string|null = null;
  privateNotes: string|null = null;

  requisitionedBy: string|null = null;

  // Row-level multiselect fields

  multiselectProductList: Array<any> = [];
  multiselectProductLoading = false;

  multiselectEmployeeList: Array<any> = [];
  multiselectEmployeeLoading = false;

  // Row fields

  // The entire product details object (productid, label, description, measurement unit, whether it is active)
  selectedProduct: any|null = null;
  selectedEmployee: any|null = null;

  // Copied from the product on selection
  productDescription: string|null = null;
  lineNotes: string|null = null;
  // Copied from the product on selection
  measurementUnit: string|null = null;

  // These affect each other
  requiredQuantity: string | null = null;
  requisitionedQuantity: string | null = null;
  // This is actually an object, the key is the VAT percentage
  vatPercentage? = { label: '19 %', key: 0.19 };
  lineValue: string | null = null;
  lineVatValue: string | null = null;

  isLastDocument: boolean = false;

  //
  // Search functions
  //

  async asyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.multiselectProductLoading = true;

    this.multiselectProductList = camelcaseKeys(await lookupProduct(query));

    this.multiselectProductLoading = false;
    return Promise.resolve();
  }

  async asyncSearchEmployees(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.multiselectEmployeeLoading = true;

    this.multiselectEmployeeList = camelcaseKeys(await lookupEmployee(query));

    this.multiselectEmployeeLoading = false;
    return Promise.resolve();
  }

  async asyncSearchInventory(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.inventoryOptions = camelcaseKeys(await lookupEmployee(query));

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  //
  // Search functions (debounced)
  //

  debouncedSearchProducts = debounce(this.asyncSearchProducts, 75, { maxWait: 150 });
  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });
  debouncedSearchEmployees = debounce(this.asyncSearchEmployees, 75, { maxWait: 150 });

  //
  // Event handlers
  //

  onEditLine(line) {
    (<any>(this.$refs.editMaterialsTransferNotesLineModal)).showModal(line);
  }

  onEditLineEvent(itemLine) {
    const item = this.items.find((e) => e.lineNumber === itemLine.lineNumber);
    if (!item) {
      this.showErrorsToast('Linia nu a putut fi actualizată', 'iarnDraftLineAddError');
      return;
    }

    // eslint-disable-next-line no-param-reassign
    item.productDescription = itemLine.productDescription;
    // eslint-disable-next-line no-param-reassign
    item.measurementUnit = itemLine.measurementUnit;
    // eslint-disable-next-line no-param-reassign
    item.requiredQuantity = itemLine.requiredQuantity;
    // eslint-disable-next-line no-param-reassign
    item.requisitionedQuantity = itemLine.requisitionedQuantity;
    // eslint-disable-next-line no-param-reassign
    item.lineNotes = itemLine.lineNotes;
    // eslint-disable-next-line no-param-reassign
    item.vatPercentage = itemLine.vatPercentage;
    // eslint-disable-next-line no-param-reassign
    item.lineValue = itemLine.lineValue;
    // eslint-disable-next-line no-param-reassign
    item.lineVatValue = itemLine.lineVatValue;
  }

  onRemoveLine(line) {
    this.items = this.items.filter((i) => i !== line);
    this.items.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.lineNumber = index + 1;
    });

    this.isSaved = false;
  }

  async onAddLine() {
    if (!this.isDraft) {
      return;
    }

    const itemLine = {
      lineNumber: this.items.length + 1,
      inventoryId: this.inventory.employeeId,
      productDescription: this.productDescription,
      productCode: this.selectedProduct?.label,
      productId: this.selectedProduct?.productId,
      lineNotes: this.lineNotes,
      measurementUnit: this.measurementUnit,
      requiredQuantity: this.requiredQuantity,
      requisitionedQuantity: this.requisitionedQuantity!,
    };

    const errors: Array<string> = [];

    // For now only allow inventoried entries
    if (!itemLine.productId) {
      errors.push('Produs neselectat');
    }
    if (!itemLine.measurementUnit) {
      errors.push('U.M. necompletată');
    }
    if (!itemLine.requiredQuantity) {
      errors.push('Cantitate necesară necompletată');
    }
    if (!itemLine.requisitionedQuantity) {
      errors.push('Cantitate consumată necompletată');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'iarnDraftLineAddError');
      return;
    }

    let totalRequisitioned = new Decimal(itemLine.requisitionedQuantity);
    this.items.filter((e) => e.productId === itemLine.productId).forEach((item) => {
      const value = new Decimal(item.requisitionedQuantity);
      totalRequisitioned = totalRequisitioned.plus(value);
    });

    if (new Decimal(totalRequisitioned).gt(0)) {
      const availableStock = await getAvailableAssets({
        lookupDate: format(parse(this.dataConsumValue, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd'),
        productId: itemLine.productId,
        employeeId: this.inventory.employeeId,
      });

      if (new Decimal(totalRequisitioned).gt(availableStock.totalQuantity)) {
        this.$bvModal.msgBoxOk(`Stoc insuficient în gestiune (${this.formatNumber(availableStock.totalQuantity, 3)} unități) pentru produsul selectat!`);
        return;
      }
    }

    this.items.push(itemLine);
    this.isSaved = false;

    this.cleanLineInput();
  }

  getLatestUsedDocumentSeriesIndex(option) {
    return option.startIndex !== option.nextIndex
      ? option.nextIndex - 1
      : option.startIndex;
  }

  cleanLineInput() {
    this.selectedProduct = null;
    this.productDescription = null;
    this.lineNotes = null;
    this.measurementUnit = 'BUC';
    this.requiredQuantity = null;
    this.requisitionedQuantity = null;
  }

  async initialize() {
    this.items = [];

    this.documentSeriesOptions = camelcaseKeys(await getAvailableSeries(this.dataConsumYear));

    let existingData: any|null = null;
    let copyData: any|null = null;
    let reverseNoteData: any|null = null;

    try {
      if (this.$route.params.id) {
        existingData = camelcaseKeys(await getDetails(this.$route.params.id));
      } else if (this.$route.query.copyFromId) {
        copyData = camelcaseKeys(await getDetails(this.$route.query.copyFromId));
      } else if (this.$route.query.reverseFromId) {
        reverseNoteData = camelcaseKeys(await getDetails(this.$route.query.reverseFromId));
      }
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
      return;
    }

    if (!existingData) {
      //
      // Fresh object!
      //
      this.dataConsumValueDatepicker = format(new Date(), 'yyyy-MM-dd');

      if (this.documentSeriesOptions.length === 1) {
        [this.documentSeries] = this.documentSeriesOptions;
      }

      if (copyData) {
        this.requisitionedBy = copyData.requisitionedBy;
        this.requisitionedFrom = copyData.requisitionedFrom;
        this.inventory = copyData.inventory;
        this.selectedEmployee = copyData.employee;
        this.documentSeries = copyData.documentSeries;

        copyData.lines.forEach((line) => {
          const itemLine = {
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: line.lineNotes,
            measurementUnit: line.measurementUnit,
            requiredQuantity: line.requiredQuantity,
            requisitionedQuantity: line.requisitionedQuantity,
          };

          this.items.push(itemLine);
        });
      }

      if (reverseNoteData) {
        this.requisitionedBy = reverseNoteData.requisitionedBy;
        this.requisitionedFrom = reverseNoteData.requisitionedFrom;
        this.inventory = reverseNoteData.inventory;
        this.selectedEmployee = reverseNoteData.employee;
        this.documentSeries = reverseNoteData.documentSeries;

        reverseNoteData.lines.forEach((line) => {
          const itemLine = {
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: 'Restituire din Bon de transfer obiect inventar '.concat(String(reverseNoteData.documentNumber)).concat(' din data de ').concat(format(parseISO(reverseNoteData.documentDate), 'dd/MM/yyyy')),
            measurementUnit: line.measurementUnit,
            // Received qty is reversed
            requiredQuantity: new Decimal(line.requiredQuantity).mul(-1).toNumber(),
            requisitionedQuantity: new Decimal(line.requisitionedQuantity).mul(-1).toNumber(),
          };

          this.items.push(itemLine);
        });
      }
    } else {
      //
      // We have a model ID!
      //
      this.statusCode = existingData.statusCode;
      this.inventoryAssetTransferNoteId = existingData.inventoryAssetTransferNoteId;
      this.documentNumber = existingData.documentNumber;
      this.dataConsumValueDatepicker = format(parseISO(existingData.documentDate), 'yyyy-MM-dd');

      this.dataConsumValue = format(parseISO(existingData.documentDate), 'dd /MM / yyyy');

      this.dataConsumYear = (new Date()).getFullYear();

      this.inventory = existingData.inventory;

      this.selectedEmployee = existingData.employee;

      this.documentSeries = existingData.documentSeries;

      // Footer
      this.documentNotes = existingData.documentNotes;
      this.privateNotes = existingData.privateNotes;
      this.requisitionedBy = existingData.requisitionedBy;
      this.requisitionedFrom = existingData.requisitionedFrom;
      this.isLastDocument = existingData.isLastDocument;

      if (this.isPublished) {
        let currentLineNumber = 1;
        existingData.lines.forEach((line) => {
          line.inventoryAllocations.forEach((invAlloc, index) => {
            const itemLine = {
              materialsRequisitionNoteLineId: line.materialsRequisitionNoteLineId,
              lineNumber: (index === 0 ? currentLineNumber : null),
              inventoryName: line.inventoryName, // not shown
              inventoryCode: line.inventoryCode, // not shown
              inventoryId: line.inventoryId, // not shown
              productDescription: (index === 0 ? line.productDescription : null),
              productCode: (index === 0 ? line.productCode : null),
              productId: line.productId, // not shown
              lineNotes: (index === 0 ? line.lineNotes : null),
              measurementUnit: (index === 0 ? line.measurementUnit : null),
              requiredQuantity: (index === 0 ? line.requiredQuantity : null),
              inventoryUnitPrice: invAlloc.inventoryUnitPrice,
              inventoryRequisitionValue: invAlloc.inventoryRequisitionValue,
              requisitionedQuantity: invAlloc.requisitionedQuantity,
            };

            currentLineNumber += 1;
            this.items.push(itemLine);
          });
        });
      } else {
        existingData.lines.forEach((line) => {
          const itemLine = {
            materialsRequisitionNoteLineId: line.materialsRequisitionNoteLineId,
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: line.lineNotes,
            measurementUnit: line.measurementUnit,
            requiredQuantity: line.requiredQuantity,
            requisitionedQuantity: line.requisitionedQuantity,
          };

          this.items.push(itemLine);
        });
      }

      // We're clean
      this.isSaved = true;
    }

    this.loading = false;
  }

  async printDocument(numCopies = 1) {
    let data: any;

    try {
      data = await getPdf(this.inventoryAssetTransferNoteId, numCopies);
    } catch (err) {
      this.$bvModal.msgBoxOk('A intervenit o eroare la pregătirea documentului pentru imprimare! Încearcă din nou în câteva momente.');
      return;
    }

    if (!(data instanceof ArrayBuffer)) {
      this.$bvModal.msgBoxOk('A intervenit o eroare la pregătirea documentului pentru imprimare! Încearcă din nou în câteva momente.');
      return;
    }

    const base64Text = btoa(new Uint8Array(<any>data).reduce((d, byte) => d + String.fromCharCode(byte), ''));
    printJS({
      printable: base64Text,
      base64: true,
    });

    // Use printjs instead
    /*
    const blob = new Blob([data], { type: 'application/pdf' });

    if (this.printPreviewIframe) {
      this.printPreviewIframe.parentElement!.removeChild(this.printPreviewIframe);
    }

    this.printPreviewIframe = document.createElement('iframe'); // Create an IFrame.
    this.printPreviewIframe.style.visibility = 'hidden'; // Hide the frame.
    this.printPreviewIframe.src = URL.createObjectURL(blob);// Set source.
    (this.$refs.componentRoot as Element).appendChild(this.printPreviewIframe); // Add the frame to the component - this way it gets cleaned up when we leave

    this.printPreviewIframe.contentWindow!.focus(); // Set focus.
    this.printPreviewIframe.contentWindow!.print(); // Print it.
    */
  }

  async onPrintModal() {
    const numCopiesDecimal = new Decimal(this.printNumCopies);
    if (!numCopiesDecimal.isInteger()
    || numCopiesDecimal.lte(0)
    || numCopiesDecimal.gte(9)) {
      this.$bvModal.msgBoxOk('Numărul de copii care vor fi imprimate trebuie să fie între 1 și 9');
      return;
    }
    await this.printDocument(numCopiesDecimal.toNumber());
  }

  onPrint() {
    this.$bvModal.show('print-modal');
  }

  onReverseNote() {
    this.$router.push({ name: 'InventoryAssetTransferNotes.Create', query: { reverseFromId: `${this.inventoryAssetTransferNoteId}` } });
  }

  async onDeleteDraft() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să ștergi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }
    try {
      if (this.inventoryAssetTransferNoteId) {
        await deleteDraft(this.inventoryAssetTransferNoteId);
      }

      toasts.success('Documentul a fost eliminat!');
      this.$router.replace({ name: 'InventoryAssetTransferNotes.Index' });
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onDeleteCanceled() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să ștergi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    if (this.inventoryAssetTransferNoteId) {
      await deleteCanceled(this.inventoryAssetTransferNoteId);
    }

    toasts.success('Documentul a fost eliminat!');
    this.$router.replace({ name: 'InventoryAssetTransferNotes.Index' });
  }

  async onCancelDocument() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să anulezi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    if (this.inventoryAssetTransferNoteId) {
      try {
        await cancelDocument(this.inventoryAssetTransferNoteId);

        toasts.success('Documentul a fost anulat!');
        this.$router.replace({ name: 'InventoryAssetTransferNotes.Index' });
      } catch (err: any) {
        toasts.error(err.response.data.errorMessage);
      }
    }
  }

  async onRestoreDocument() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să restaurezi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }
    if (this.inventoryAssetTransferNoteId) {
      try {
        await restoreDocument(this.inventoryAssetTransferNoteId);
        toasts.success('Documentul a fost restaurat!');
        this.$router.replace({ name: 'InventoryAssetTransferNotes.Index' });
      } catch (err: any) {
        toasts.error(err.response.data.errorMessage);
      }
    }
  }

  async onShowDocumentLogs() {
    const logs = await getDocumentLogs(this.inventoryAssetTransferNoteId);
    this.documentLogs = logs.items?.reverse()?.map((e, index) => ({ ...e, index: index + 1 }));
    this.documentLogs = this.documentLogs.reverse();
    (<any>(this.$refs.documentLogEntriesModal)).showModal();
  }

  async onSaveDocument() {
    const errors: Array<string> = [];

    if (!this.items.length) {
      errors.push('Trebuie să adaugi măcar o linie');
    }

    if (!this.requisitionedBy) {
      errors.push('Nume primitor necompletat');
    }

    if (!this.documentSeries) {
      errors.push('Serie documente neselectată');
    }

    if (!this.selectedEmployee) {
      errors.push('Marca neselectată');
    }

    if (!this.items || !this.items.length) {
      errors.push('Documentul nu are linii');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'iarnDraftSaveError');
      return;
    }

    const draft = {
      inventoryAssetTransferNoteId: this.inventoryAssetTransferNoteId,
      inventoryId: this.inventory.employeeId,
      employeeInternalId: this.selectedEmployee.employeeId,

      documentDate: this.dataConsumValueDatepicker,
      documentSeriesId: this.documentSeries.documentSeriesId,

      privateNotes: this.privateNotes,
      documentNotes: this.documentNotes,
      requisitionedBy: this.requisitionedBy,

      // TODO: order by line number, perhaps? Just in case they're not in order
      lines: this.items.map((item) => ({
        materialsRequisitionNoteLineId: item.doodsReceivedNoteLineId,
        lineTypeCode: 'BCO',
        inventoryId: item.inventoryId,
        productId: item.productId,
        productDescription: item.productDescription,
        measurementUnit: item.measurementUnit,
        requiredQuantity: item.requiredQuantity,
        requisitionedQuantity: item.requisitionedQuantity,
        lineNotes: item.lineNotes,
      })),
    };

    try {
      const response = camelcaseKeys(await saveDraft(draft));
      toasts.success('Documentul a fost salvat ca ciornă!');
      this.isSaved = true;
      this.inventoryAssetTransferNoteId = response.item1.inventoryAssetTransferNoteId;

      const compareLatestReponse = await compareLatestSeries(this.inventoryAssetTransferNoteId);
      if (compareLatestReponse.isCurrentDocumentDateEarlierThanLatest) {
        this.$bvModal.show('previous-series-warning-modal');
      } else {
        this.previewModalImageSrc = `/api/bto/jpeg/${this.inventoryAssetTransferNoteId}?cb=${+new Date()}`;
        this.$bvModal.show('preview-modal');
      }
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onPreviousSeriesWarningModalOk(evt) {
    evt.preventDefault();

    this.previewModalImageSrc = `/api/bto/jpeg/${this.inventoryAssetTransferNoteId}?cb=${+new Date()}`;
    this.$bvModal.show('preview-modal');
  }

  // beforeRouteEnter(to, from, next) {

  // }

  // beforeRouteUpdate(to, from, next) {

  // }

  async beforeRouteLeave(to, from, next) {
    if (this.isSaved) {
      next();
      return;
    }

    try {
      if (await this.$bvModal.msgBoxConfirm('Informațiile nesalvate vor fi pierdute! Dorești să părăsești pagina curentă?', {
        title: 'Atenție',
        okTitle: 'Da',
        cancelTitle: 'Nu',
        centered: true,
      })) {
        next();
      }
      next(false);
    } catch (err) {
      next(false);
    }
  }

  async created() {
    await this.initialize();
  }
}
