













































































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import debounce from 'lodash/debounce';
import camelcaseKeys from 'camelcase-keys';
import Decimal from 'decimal.js';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { formatNumber } from '../../../utils/number-formatting';

import InventoryAssetRequisitionNoteMixin from '../../inventory-asset-requisition-notes/views/inventory-asset-requisition-notes-mixin.vue';

import {
  lookupProduct,
  getAvailableInventories,
  getAvailableAssets,
} from '../api/inventory-asset-transfer-notes-api';

@Component
export default class extends mixins(InventoryAssetRequisitionNoteMixin) {
  @Prop() inventory: any;
  @Prop() dataConsumValue: any;
  @Prop() items!: Array<any>;

  editInventoryId: Number = 0;
  editLineNotes: any = null;
  editLineNumber: Number = 0;
  editMeasurementUnit: any = null;
  editProductCode: any = null;
  editProductDescription: any = null;
  editProductId: Number = 0;
  editRequiredQuantity: any = null;
  editRequisitionedQuantity: any = null;

  editSelectedProduct = { productId: '', label: '' }

  editMultiselectProductLoading: boolean = false;
  editMultiselectProductList: Array<any> = [];

  editAllInventoryOptions: Array<any> = [];
  editInventoryOptions: Array<any> = [];
  editIsInventoryOptionsLoading: boolean = false;

  debouncedSearchProducts = debounce(this.editAsyncSearchProducts, 75, { maxWait: 150 });
  debouncedSearchInventory = debounce(this.editAsyncSearchInventory, 75, { maxWait: 150 });

  async editAsyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.editMultiselectProductLoading = true;

    this.editMultiselectProductList = camelcaseKeys(await lookupProduct(query));

    this.editMultiselectProductLoading = false;
    return Promise.resolve();
  }

  async editAsyncSearchInventory() {
    if (this.editAllInventoryOptions.length) {
      this.editInventoryOptions = this.editAllInventoryOptions.filter((o) => o.isUserAssociated);
      return Promise.resolve();
    }

    this.editIsInventoryOptionsLoading = true;

    this.editAllInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.editInventoryOptions = this.editAllInventoryOptions.filter((o) => o.isUserAssociated);

    this.editIsInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  onEditRequisitionedQuantityChanged(value) {
    this.editRequisitionedQuantity = (!value) ? '0' : value;
    if (!this.editRequiredQuantity) {
      this.editRequiredQuantity = new Decimal(this.editRequisitionedQuantity).toFixed(3);
    }
  }

  onEditRequiredQuantityChanged(value) {
    this.editRequiredQuantity = (!value) ? '0' : value;
    this.editRequisitionedQuantity = new Decimal(this.editRequiredQuantity).toFixed(3);
  }

  onEditProductSelected(value) {
    if (!value) {
      return;
    }

    this.editProductDescription = value.description;
    this.editMeasurementUnit = value.measurementUnit;

    (this.$refs.editProductDescriptionInputControl as any).focus();
  }

  onEditNoteLineModalCancel() {
    this.cleanEditLineInput();
    (<any>(this.$refs.materialModal)).hide();
  }

  async onEditNoteLineModalOk(event) {
    event.preventDefault();
    const result = await this.onSubmitChanges();

    if (result) {
      console.log(result);
      this.cleanEditLineInput();
      this.$emit('onEditLineEvent', result);
      (<any>(this.$refs.materialModal)).hide();
    }
  }

  showModal(line) {
    this.editInventoryId = line.inventoryId;
    this.editLineNotes = line.lineNotes;
    this.editLineNumber = line.lineNumber;
    this.editProductCode = line.productCode;
    this.editProductDescription = line.productDescription;
    this.editProductId = line.productId;
    this.editRequiredQuantity = line.requiredQuantity;
    this.editRequisitionedQuantity = line.requisitionedQuantity;
    this.editMeasurementUnit = line.measurementUnit;

    this.editSelectedProduct.label = line.productCode;
    this.editSelectedProduct.productId = line.productId;

    (<any>(this.$refs.materialModal)).show();
  }

  cleanEditLineInput() {
    this.editInventoryId = 0;
    this.editLineNotes = null;
    this.editLineNumber = 0;
    this.editMeasurementUnit = null;
    this.editProductCode = null;
    this.editProductDescription = null;
    this.editProductId = 0;
    this.editRequiredQuantity = null;
    this.editRequisitionedQuantity = null;

    this.editSelectedProduct = { productId: '', label: '' };

    this.editMultiselectProductLoading = false;
    this.editMultiselectProductList = [];

    this.editAllInventoryOptions = [];
    this.editInventoryOptions = [];
    this.editIsInventoryOptionsLoading = false;
  }

  async onSubmitChanges() {
    const errors: Array<string> = [];

    const itemLine = {
      lineNumber: this.editLineNumber,
      inventoryId: this.editInventoryId,
      productDescription: this.editProductDescription,
      productCode: this.editSelectedProduct?.label,
      productId: this.editSelectedProduct?.productId,
      lineNotes: this.editLineNotes,
      measurementUnit: this.editMeasurementUnit,
      requiredQuantity: this.editRequiredQuantity,
      requisitionedQuantity: this.editRequisitionedQuantity!,
    };

    if (!itemLine.productId) {
      errors.push('Produs neselectat');
    }
    if (!itemLine.measurementUnit) {
      errors.push('U.M. necompletată');
    }
    if (!itemLine.requiredQuantity) {
      errors.push('Cantitate necesară necompletată');
    }
    if (!itemLine.requisitionedQuantity) {
      errors.push('Cantitate consumată necompletată');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'iarnDraftLineAddError');
      return null;
    }

    let totalRequisitioned = new Decimal(itemLine.requisitionedQuantity);
    this.items.filter((e) => e.productId === itemLine.productId
      && e.lineNumber !== itemLine.lineNumber).forEach((item) => {
      const value = new Decimal(item.requisitionedQuantity);
      totalRequisitioned = totalRequisitioned.plus(value);
    });

    if (new Decimal(totalRequisitioned).gt(0)) {
      const availableStock = await getAvailableAssets({
        lookupDate: format(parse(this.dataConsumValue, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd'),
        productId: itemLine.productId,
        employeeId: this.inventory.employeeId,
      });

      if (new Decimal(totalRequisitioned).gt(availableStock.totalQuantity)) {
        this.$bvModal.msgBoxOk(`Stoc insuficient în gestiune (${formatNumber(availableStock.totalQuantity, 3)} unități) pentru produsul selectat!`);
        return null;
      }
    }

    return itemLine;
  }
}
